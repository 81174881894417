.modal {
  @include animation-preset;
  position: fixed;
  background-color: white;
  padding: 0.75rem 1.25rem 2rem 1.25rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1003;
  text-align: left;
  pointer-events: none;
  transform: translate3d(0, 1.25rem, 0);
  opacity: 0;
  transition: opacity 150ms ease-out, transform 300ms ease-out;
  @include shadow-soft;
  font-weight: 400;

  &.active {
    pointer-events: all;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: opacity 300ms ease-out, transform 300ms ease-out;
  }

  .modal-header {
    display: grid;
    grid-template-columns: 1fr 3rem;
    gap: 0 1rem;
    align-items: center;
  }

  .modal-body {
    max-height: calc(100vh - 6.25rem);
    overflow-y: auto;
    margin-top: 0.5rem;
  }
}

@media only screen and (min-width: 600px) {
  .modal {
    width: 27.5rem;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, calc(-50% + 1.25rem), 0);
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 2rem;

    &.active {
      transform: translate3d(-50%, -50%, 0);
    }

    .modal-body {
      padding-right: 1rem;
    }
  }
}

.overlay-modal {
  z-index: 1002;
}
