.table-filters {
  margin: 0.75rem 0 0.5rem;

  .table-filters-button {
    @include button-preset;
    background-color: white;
    color: $blue-medium;
    font: 700 1rem/1.5rem $font;
    letter-spacing: 0.2px;
    cursor: pointer;
    padding: 0.625rem 1rem;
    border-radius: 1.375rem;
    margin-right: 0.5rem;

    &.active {
      background-color: $blue-medium;
      color: white;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $blue-medium;
        color: white;
      }
    }

    &:focus {
      outline: none;
      background-color: $blue-medium;
      color: white;
    }

    &:focus:focus-visible {
      outline: $blue-medium auto 1px;
    }
  }
}

@media only screen and (min-width: 900px) {
  .table-filters {
    margin-bottom: 1rem;
  }
}
