.external-link {
  display: grid;
  grid-template-columns: 1rem 1fr;
  line-height: 1.5rem;
  gap: 0.25rem;
  text-decoration: none;

  .icon-external-link {
    display: block;
    font-size: 1rem;
    margin-top: 0.25rem;
  }

  .external-link-text {
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }

    &:focus {
      outline: none;
      text-decoration: none;
    }

    &:focus:focus-visible {
      outline: $blue-medium auto 1px;
    }
  }
}

.link-blue {
  color: $blue-medium;
}
