.checkbox {
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  gap: 0 0.875rem;
  margin-bottom: 0.875rem;
  position: relative;
  max-width: 100%;

  @supports (width: max-content) {
    width: max-content;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    grid-column: 1/3;

    &:checked ~ .checkbox-checkmark {
      background-color: $green;
      border: 0.125rem solid $green;
    }
  }

  .checkbox-checkmark {
    display: block;
    width: 1.375rem;
    height: 1.375rem;
    background: white;
    border: 0.125rem solid $grey-light;
    border-radius: 0.125rem;
    margin: calculate-rem(1px) 0;
    position: relative;

    &:after {
      @include animation-preset;
      font-family: "icomoon" !important;
      display: block;
      content: "\e91e";
      font-size: 1.5rem;
      line-height: 1.5rem;
      position: absolute;
      left: calculate-rem(-3px);
      top: calculate-rem(-3px);
      color: white;
    }
  }

  .checkbox-label {
    line-height: 1.5rem;
  }
}

@media only screen and (min-width: 700px) {
  .checkbox {
    gap: 0 0.625rem;
  }
}
