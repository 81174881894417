.button-link {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  position: relative;
  text-decoration: none;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-radius: 1.25rem;

  @supports (width: max-content) {
    width: max-content;
  }

  .button-link-text {
    font: 700 0.875rem/1.5rem $font;
  }
}

@media only screen and (min-width: 1100px) {
  .button-link {
    .button-link-text {
      font-size: 1rem;
    }
  }
}
