@import "./Checkbox/checkbox";
@import "./Input/input";
@import "./InputPassword/input-password";
@import "./Label/label";
@import "./Note/note";
@import "./FormSection/form-section";

.form-wrapper {
  margin: 0 auto;
  width: 100%;
}

.form {
  background-color: white;
  border-radius: 1rem;
  @include shadow-soft;
}

.form-error-text {
  display: block;
  min-height: 1.5rem;
  color: $pink-dark;
  font: 400 0.875rem/1.5rem $font;
  margin: 0.125rem 0;
}

@media only screen and (min-width: 700px) {
  .form-error-text {
    display: block;
    min-height: 1.5rem;
    color: $pink-dark;
    font: 400 0.875rem/1.5rem $font;
    margin: 0.25rem 0 0.25rem;
  }
}
