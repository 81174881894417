@font-face {
  font-family: "icomoon";
  src: url("../../icons/icomoon.eot?m8nt26");
  src: url("../../icons/icomoon.eot?m8nt26#iefix") format("embedded-opentype"),
    url("../../icons/icomoon.ttf?m8nt26") format("truetype"),
    url("../../icons/icomoon.woff?m8nt26") format("woff"),
    url("../../icons/icomoon.svg?m8nt26#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-bin:before {
  content: "\e901";
}
.icon-chevron-right:before {
  content: "\e902";
  display: block;
}
.icon-chevron-left:before {
  content: "\e902";
  display: block;
  transform: rotate(-180deg);
}
.icon-chevron-down:before {
  content: "\e902";
  display: block;
  transform: rotate(90deg);
}
.icon-cross-circle:before {
  content: "\e903";
}
.icon-cross:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e907";
}
.icon-external-link:before {
  content: "\e908";
}
.icon-eye-hide:before {
  content: "\e909";
}
.icon-eye-show:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-grid:before {
  content: "\e90c";
}
.icon-info:before {
  content: "\e90d";
}
.icon-instagram:before {
  content: "\e90e";
}
.icon-linked-in:before {
  content: "\e90f";
}
.icon-menu-open:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e911";
}
.icon-minus:before {
  content: "\e912";
}
.icon-order:before {
  content: "\e913";
}
.icon-padlock:before {
  content: "\e914";
}
.icon-phone-call:before {
  content: "\e915";
}
.icon-pin:before {
  content: "\e916";
}
.icon-plus:before {
  content: "\e917";
}
.icon-quote:before {
  content: "\e918";
}
.icon-send:before {
  content: "\e919";
}
.icon-shield:before {
  content: "\e91a";
}
.icon-star:before {
  content: "\e91b";
}
.icon-tick-circle-untick:before {
  content: "\e91c";
}
.icon-tick-circle:before {
  content: "\e91d";
}
.icon-tick:before {
  content: "\e91e";
}
.icon-tool:before {
  content: "\e91f";
}
.icon-twitter:before {
  content: "\e920";
}
.icon-upload:before {
  content: "\e921";
}
.icon-warning:before {
  content: "\e922";
}
.icon-wedge:before {
  content: "\e923";
}
.icon-zoom-in:before {
  content: "\e924";
}
.icon-zoom-out:before {
  content: "\e925";
}
