table {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;

  td {
    padding: 0;
    margin-bottom: 0;
    border: none;
    border-collapse: separate;
  }
}
