.icon-button {
  @include button-preset;
  padding: 0.75rem;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: $grey-lighter;
    }
  }

  &:focus {
    outline: none;
    background-color: $grey-lighter;
  }

  &:focus:focus-visible {
    outline: $blue-medium auto 1px;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    color: $grey-medium;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    text-align: center;
  }
}
