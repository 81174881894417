.note {
  border-radius: 0.5rem;
  border: 1px solid $grey-lighter;
  padding: 0.75rem 1rem;

  .note-header {
    @include flex-justify-start;
    margin-bottom: 0.25rem;
    .note-header-user {
      display: block;
      font: 700 0.875rem/1.25rem $font;
      color: black;
      margin-right: 0.75rem;
    }

    .note-header-posted {
      display: block;
      font: 400 0.875rem/1.25rem $font;
    }
  }

  .note-body {
    font: 400 0.875rem/1.5rem $font;
  }
}

.note-container {
  padding-top: 0.5rem;
  display: grid;
  gap: 1rem;
}
