.input-password {
  position: relative;

  .input-field {
    padding-right: 3rem;
  }

  .input-password-button {
    @include button-preset;
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;

    &:focus {
      outline: none;
      background-color: $grey-lighter;
    }

    &:focus:focus-visible {
      outline: $blue-medium auto 1px;
      background-color: $grey-lighter;
    }

    .icon-eye-show,
    .icon-eye-hide {
      color: $grey-light;
      display: block;
    }
  }
}
