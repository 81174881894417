@import "./ButtonLink/button-link";
@import "./IconButton/icon-button";

@keyframes loaderSpin {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

%button {
  @include button-preset;
  @include animation-preset;
  cursor: pointer;
  font: 700 1rem/1.5rem $font;
  display: inline-block;
  padding: 0.875rem 1.25rem;
  text-decoration: none;
  text-align: center;
  border-radius: 0.25rem;

  &.button-with-icon {
    position: relative;

    .button-with-icon-text {
      font: 700 1rem/1.5rem $font;
    }

    .button-icon {
      display: block;
      font-family: "icomoon";
      position: absolute;
      top: 0.875rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    &.icon-left {
      padding-left: 2.875rem;

      .button-icon {
        left: 0.875rem;
      }
    }

    &.icon-right {
      padding-right: 2.875rem;

      .button-icon {
        right: 0.875rem;
      }
    }
  }

  &.button-small {
    padding: 0.75rem 1.125rem;
  }
}

.button-primary {
  @extend %button;
  color: white;
  background-color: $blue-medium;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    filter: brightness(82.5%);
  }

  @media (hover: hover) {
    &:hover {
      filter: brightness(82.5%);
    }
  }

  &.green {
    background-color: $green;
  }

  &.button-with-loader {
    &:disabled {
      &:before {
        @include animation-preset;
        display: block;
        content: "";
        border: rgba(white, 0.24) 0.125rem solid;
        border-top: white 0.125rem solid;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        animation: loaderSpin 600ms forwards infinite;
      }

      .button-with-loader-text {
        visibility: hidden;
      }
    }
  }

  &.button-with-loader-payment {
    &.loading {
      &:before {
        @include animation-preset;
        display: block;
        content: "";
        border: rgba(white, 0.24) 0.125rem solid;
        border-top: white 0.125rem solid;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        animation: loaderSpin 600ms forwards infinite;
      }

      .button-with-loader-text {
        visibility: hidden;
      }
    }
  }
}

.button-secondary {
  @extend %button;
  color: $blue-medium;
  background-color: $blue-light;
}

.button-submit {
  margin-top: 1.5rem;
  width: 100%;
}

.button-checkout {
  margin: 1.5rem 0;
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .button-submit {
    margin-top: 2rem;
  }

  .button-checkout {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
}
