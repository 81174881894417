.section-with-sidebar {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  padding-bottom: 1.25rem;
}

@media only screen and (min-width: 1100px) {
  .section-with-sidebar {
    display: grid;
    grid-template-columns: 1fr 21rem;
    gap: 2rem;
    padding-bottom: 3rem;
  }
}
