.data-display {
  &.large {
    .data-display-label {
      color: $grey-dark;
      margin-bottom: 0.25rem;
    }

    .data-display-data {
      color: black;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .data-display-label {
    display: block;
    font: 700 0.75rem/1rem $font;
    color: black;
  }

  .data-display-data:first-letter {
    text-transform: capitalize;
  }

  .data-display-data-buttons {
    margin-top: 0.25rem;
    @include flex-justify-start;

    .button-primary,
    .button-secondary {
      margin: 0.5rem 0.75rem 0 0;
      display: block;
    }
  }
}

.data-display-photo {
  padding: 0.75rem;
  background-color: $blue-light;
  margin-top: 0.5rem;
}

@media only screen and (min-width: 900px) {
  .data-display {
    &.large {
      .data-display-label {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      .data-display-data {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    .data-display-label {
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0.25rem;
    }

    .data-display-data-buttons {
      margin-top: 0.75rem;
      margin-bottom: 0.25rem;

      .button-primary,
      .button-secondary {
        margin-top: 0.25rem;
      }
    }
  }
}
