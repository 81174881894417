@import "./date-picker";

.chart-dates {
  @include flex-justify-start;
  background-color: white;
  @include shadow-soft;
  border-radius: 0.5rem;
  margin: 1rem 0 1.25rem;
  padding: 0.75rem 1rem;
  align-items: center;
  max-width: 100%;

  @supports (width: max-content) {
    width: max-content;
  }

  .react-date-picker {
    width: 100%;

    &:first-of-type {
      margin-bottom: 0.75rem;
    }
  }

  .chart-dates-divider {
    display: none;
  }
}

.chart-loader {
  border-radius: 0.5rem;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &.loaded {
    opacity: 0;
    pointer-events: none;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      @include animation-preset;
      width: 2rem;
      height: 2rem;
      margin: 0 auto;
      animation: spin 600ms forwards infinite;
    }
  }
}

.chart {
  .heading-small {
    margin-bottom: 0.25rem;
  }
}

@media only screen and (min-width: 500px) {
  .chart-dates {
    .react-date-picker {
      width: auto;

      &:first-of-type {
        margin-bottom: 0;
      }
    }

    .chart-dates-divider {
      display: block;
      margin: 0 1rem;
      color: $grey-medium;
    }
  }
}

@media only screen and (min-width: 700px) {
  .chart-dates {
    padding: 1rem 1.25rem;
  }
}

@media only screen and (min-width: 1100px) {
  .chart-dates {
    margin: 1.25rem 0 1.5rem;
  }

  .chart {
    padding-bottom: 0.5rem;

    .heading-small {
      margin-bottom: 0.25rem;
    }
  }
}
