.loader-container {
  position: relative;
  overflow-y: auto;

  &.full-screen {
    height: 100%;
  }

  &.list-view {
    // Heading height + 1 row of filters height
    height: calc(100% - 6.75rem);
  }

  &.single-view {
    height: 100%;
    padding-top: 0.5rem;
  }
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $grey-lightest;

  .loader-layout {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;

    svg {
      @include animation-preset;
      width: 2rem;
      height: 2rem;
      margin: 0 auto;
      animation: spin 600ms forwards infinite;
    }

    .icon-warning {
      display: block;
      width: 1.5rem;
      font-size: 1.5rem;
      margin: 0 auto;
      color: $pink-dark;
    }

    .heading-medium {
      width: 100%;
      display: block;
      margin-top: 0.5rem;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 700px) {
  .loader {
    .loader-layout {
      width: 25rem;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      .icon-warning {
        display: block;
        width: 2rem;
        font-size: 2rem;
        margin: 0 auto;
        color: $pink-dark;
      }

      .heading-medium {
        margin-top: 0.75rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .loader-container {
    &.single-view {
      padding-top: 1.5rem;
    }

    &.list-view {
      // Heading height increased so increase
      height: calc(100% - 9rem);
    }
  }
}
