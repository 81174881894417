.form-section {
  display: grid;
  gap: 0;
}

@media only screen and (min-width: 700px) {
  .form-section {
    &.form-section-2 {
      grid-template-columns: 1fr 1fr;
      gap: 0 2rem;
    }
  }
}
