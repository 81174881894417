.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  width: 100vw;
  background-color: white;
  padding: 0.5rem 0;

  .header-layout {
    @include flex-space-between;
    align-items: center;
    max-width: 91rem;
    margin: 0 auto;
    padding: 0 0.5rem 0 1.25rem;

    .header-logo {
      width: 10.5rem;
    }

    .header-button {
      @include button-preset;
      cursor: pointer;
      border-radius: 1.25rem;
      line-height: 1.5rem;
      padding: 0.5rem 1rem;
      font-weight: 700;

      @media (hover: hover) {
        &:hover {
          background-color: $grey-lighter;
        }
      }

      &:focus {
        background-color: $grey-lighter;
        outline: none;
      }

      &:focus:focus-visible {
        outline: $blue-medium auto 1px;
      }
    }
  }
}

.layout {
  padding: 0;
  height: calc(100% - 3.5rem);
  max-width: 91rem;
  margin: 0 auto;
  display: grid;
  grid-template-rows: calc(100% - 3.5rem) 3.5rem;

  .nav {
    background-color: white;
    padding: 0 1.25rem;
    grid-row: 2;

    .nav-items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;

      .nav-item a {
        display: block;
        text-decoration: none;
        padding: calculate-rem(7px) 0;
        border-radius: 0.25rem;
        background-color: transparent;

        @media (hover: hover) {
          &:hover {
            background-color: #e6e6e6;
          }
        }

        &:focus {
          background-color: #e6e6e6;
          outline: none;
        }

        &:focus:focus-visible {
          outline: $blue-medium auto 1px;
        }

        &.active {
          background-color: $blue-medium;
          color: white;

          .nav-item-text {
            color: white;
          }
        }

        .nav-item-icon {
          display: block;
        }

        .nav-item-text {
          margin-top: 0.125rem;
          font: 700 0.75rem/1rem $font;
          color: $grey-darker;
          display: block;
        }
      }
    }
  }

  .layout-screen {
    grid-row: 1;
    padding: 0 1.25rem 0 1.25rem;
    overflow-y: auto;
    position: relative;
    height: 100%;

    > div {
      height: 100%;
    }

    .heading-large {
      padding-top: 1rem;
    }

    .layout-screen-header {
      margin-bottom: 0.75rem;

      .heading-large {
        font-size: 1.5rem;
        padding: 0 0 0.125rem 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .header {
    padding: 1rem 0;

    .header-layout {
      padding-left: 3rem;
      padding-right: 3rem;

      .header-logo {
        width: 12.25rem;
      }
    }
  }

  .layout {
    padding: 0 3rem;
    height: calc(100% - 4.5rem);
    grid-template-rows: auto;
    grid-template-columns: 12rem 1fr;
    gap: 3rem;

    .nav {
      grid-row: 1;
      background-color: transparent;
      padding: 1.5rem 0 0 0;

      .nav-items {
        grid-template-columns: 1fr;
        gap: 0.125rem;
        text-align: left;

        .nav-item a {
          display: grid;
          grid-template-columns: 1.5rem 1fr;
          gap: 0.5rem;
          padding: 0.5rem 0.75rem;

          .nav-item-text {
            margin-top: 0;
            line-height: 1.5rem;
            font-size: 0.875rem;
          }
        }
      }
    }

    .layout-screen {
      padding: 0;

      .heading-large {
        padding-top: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .layout {
    grid-template-columns: 13.5rem 1fr;
    gap: 4rem;

    .nav {
      .nav-items {
        .nav-item a {
          .nav-item-text {
            font-size: 1rem;
          }
        }
      }
    }

    .layout-screen {
      .layout-screen-header {
        @include flex-justify-start;
        align-items: center;
        margin-bottom: 1rem;

        .heading-large {
          margin-top: 0.25rem;
          font-size: 1.75rem;
          margin-right: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .header {
    .header-layout {
      max-width: 84rem;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .layout {
    max-width: 84rem;
    padding-left: 0;
    padding-right: 0;
  }
}
