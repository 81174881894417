@import "./TableFilters/table-filters";

.table {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;

  @media only screen and (min-width: 1100px) {
    margin-bottom: 3rem;
  }

  .table-header-row {
    .table-header-cell {
      font-weight: 700;
      line-height: 1.25rem;
      padding: 0.25rem 0 0.625rem 0.75rem;

      &.mobile-large,
      &.tablet,
      &.desktop,
      &.desktop-large {
        display: none;
      }

      @media only screen and (min-width: 400px) {
        &.mobile-large {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 700px) {
        &.tablet {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 1100px) {
        &.desktop {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 1280px) {
        &.desktop-large {
          display: table-cell;
        }
      }
    }
  }

  .table-row {
    background-color: white;
    @include shadow-soft;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background-color: $grey-lightest;
      }
    }

    &:focus {
      outline: none;
      background-color: $grey-lightest;
    }

    &:focus:focus-visible {
      outline: $blue-medium auto 1px;
    }

    .table-cell {
      padding: 0.75rem 0 0.75rem 0.75rem;
      border-bottom: 1px solid $grey-lightest;
      line-height: 1.5rem;

      &.table-cell-index {
        font-weight: 700;
        color: $blue-medium;
        font-size: 1rem;
      }

      &.table-cell-bold {
        font-weight: 700;
        color: black;
      }

      &.table-cell-boolean {
        .icon-tick-circle {
          display: block;
          color: $green;
        }
        .icon-cross-circle {
          display: block;
          color: $grey-light;
        }
      }

      &.mobile-large,
      &.tablet,
      &.desktop,
      &.desktop-large {
        display: none;
      }

      @media only screen and (min-width: 400px) {
        &.mobile-large {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 700px) {
        &.tablet {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 1100px) {
        &.desktop {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 1280px) {
        &.desktop-large {
          display: table-cell;
        }
      }
    }
  }
}
