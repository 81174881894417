@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
$font: "Noto Sans", sans-serif;

body {
  font: 400 0.875rem/1.625rem $font;
  color: $grey-dark;
}

.heading-large {
  font: 700 1.25rem/1.75rem $font;
  color: black;

  &.heading-large-blue {
    color: $blue-medium;
  }
}

.heading-medium {
  font: 700 1rem/1.375rem $font;
  color: black;
}

.heading-small {
  font: 700 0.875rem/1.25rem $font;
}

@media only screen and (min-width: 700px) {
  .heading-medium {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .heading-small {
    font: 700 1rem/1.5rem $font;
  }
}

@media only screen and (min-width: 1100px) {
  body {
    font-size: 1rem;
    line-height: 1.875rem;
  }

  .heading-large {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}
