.card {
  background-color: white;
  border-radius: 0.5rem;
  @include shadow-soft;
  position: relative;

  .card-section {
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid $grey-lighter;
    display: grid;
    gap: 1.25rem;

    &:last-of-type {
      border-bottom: none;
    }

    .tag {
      @supports (width: max-content) {
        width: max-content;
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .card {
    .card-section {
      padding: 1.25rem 1.5rem;
      gap: 1.5rem 2rem;

      &.card-section-col-2 {
        grid-template-columns: 1fr 1fr;

        .note-container,
        .form-wrapper {
          grid-column: 1/3;
        }
      }
    }
  }
}
