/* Only way can edit Snackbar close button focus state */
[class^="Snackbar_snackbar__close"] {
  &:focus {
    outline: none;
  }

  &:focus:focus-visible {
    outline: $blue-medium auto 1px;
  }
}
