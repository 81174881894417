.react-date-picker {
  .react-date-picker__wrapper {
    border: 1px solid $grey-lighter;
    border-radius: 0.25rem;
    line-height: 1.5rem;

    .react-date-picker__inputGroup__day {
      padding: 0.75rem 0 0.75rem 1rem;
      height: auto;

      &:focus {
        outline: none;
      }

      &:focus:focus-visible {
        outline: $blue-medium auto 1px;
      }
    }

    .react-date-picker__inputGroup__month {
      padding: 0.75rem 0;
      height: auto;

      &:focus {
        outline: none;
      }

      &:focus:focus-visible {
        outline: $blue-medium auto 1px;
      }
    }

    .react-date-picker__inputGroup__year {
      padding: 0.75rem 0;
      height: auto;

      &:focus {
        outline: none;
      }

      &:focus:focus-visible {
        outline: $blue-medium auto 1px;
      }
    }

    .react-date-picker__button {
      padding: 0.625rem;
      margin: 0.125rem 0.375rem 0.125rem 0.25rem;
      border-radius: 50%;

      @media (hover: hover) {
        &:hover {
          background-color: $grey-lighter;

          svg {
            g {
              fill: $grey-medium;
            }
          }
        }
      }

      &:focus {
        outline: none;
        background-color: $grey-lighter;
      }

      &:focus:focus-visible {
        outline: $blue-medium auto 1px;
      }
    }
  }
}

.react-calendar {
  background-color: white;
  border-radius: 1rem;
  @include shadow-hard;
  border: none;

  .react-calendar__navigation {
    align-items: center;
    margin: 0;
    height: auto;

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      cursor: pointer;
      @include button-preset;
      padding: calculate-rem(15px) 1rem;
      border-radius: 50%;
      margin: 0.25rem;

      @media (hover: hover) {
        &:hover {
          background-color: $grey-lighter;

          svg {
            path {
              fill: $grey-medium;
            }
          }
        }
      }

      &:focus {
        outline: none;
        background-color: $grey-lighter;

        svg {
          path {
            fill: $grey-medium;
          }
        }
      }

      &:focus:focus-visible {
        outline: $blue-medium auto 1px;
      }

      svg {
        width: 0.5rem;
        height: 0.875rem;
      }
    }

    .react-calendar__navigation__next-button {
      transform: rotate(180deg);
    }

    .react-calendar__navigation__label {
      display: block;
      @include button-preset;
      font: 700 1rem/2rem $font;
      color: black;
      pointer-events: none;
    }
  }
  .react-calendar__viewContainer {
    .react-calendar__month-view__weekdays {
      .react-calendar__month-view__weekdays__weekday {
        text-align: center;
        padding: 0;

        abbr {
          display: block;
          font: 700 0.75rem/1.25rem $font;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          text-decoration: none;
          padding: 0.375rem 0;
        }
      }
    }

    .react-calendar__month-view__days {
      .react-calendar__tile {
        @include button-preset;

        @media (hover: hover) {
          &:hover {
            abbr {
              background-color: $green;
              color: white;
            }
          }
        }

        &:focus {
          outline: none;

          abbr {
            background-color: $green;
            color: white;
          }
        }

        &:focus:focus-visible {
          outline: $blue-medium auto 1px;
        }

        abbr {
          background-color: transparent;
          text-align: center;
          cursor: pointer;
          color: black;
          padding: 0.625rem;
          font: 700 0.875rem/1.75rem $font;
          display: block;
        }
      }
    }
  }
}
