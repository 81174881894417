.label {
  font: 700 0.875rem/1.25rem $font;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 3.75rem;
  gap: 0 1rem;

  .label-helper-text {
    display: inline-block;
    font: 400 0.75rem/1.25rem $font;
    margin-left: 0.25rem;
    color: $grey-medium;
  }

  .label-link-button {
    @include button-preset;
    display: inline-block;
    font: 400 0.875rem/1.25rem $font;
    text-decoration: underline;
    color: $blue-medium;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }

    &:focus {
      outline: none;
      text-decoration: none;
    }

    &:focus:focus-visible {
      outline: $blue-medium auto 1px;
    }
  }

  .optional {
    font-weight: 400;
    color: $grey-medium;
  }
}

@media only screen and (min-width: 700px) {
  .label {
    font-size: 1rem;
    margin-bottom: 0.75rem;
    grid-template-columns: 1fr 4rem;

    .label-helper-text {
      font-size: 0.875rem;
    }

    .label-link-button {
      font-size: 1rem;
    }
  }
}
