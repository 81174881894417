.tag {
  display: inline-block;
  padding: 0.125rem 0.375rem;
  background-color: $grey-lighter;
  font: 400 0.75rem/1rem $font;
  border-radius: 0.25rem;

  &.tag-warning {
    background-color: $yellow;
    color: black;
  }

  &.tag-alert {
    background-color: $pink-dark;
    color: white;
  }
}

@media only screen and (min-width: 700px) {
  .tag {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
