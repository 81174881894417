.line-items {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem 0;

  .line-item {
    @include flex-space-between;

    .line-item-contents {
      width: calc(100% - 4rem);
      background-color: $grey-lightest;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.25rem;

      .line-item-contents-text {
        display: block;
        line-height: 1.5rem;
      }

      .line-item-contents-price {
        font-weight: 700;
        color: black;
      }
    }

    .icon-button {
      background-color: $blue-light;

      @media (hover: hover) {
        &:hover {
          filter: brightness(95%);
        }
      }

      &:focus {
        filter: brightness(95%);
      }

      .icon-bin {
        color: $blue-medium;
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .line-items {
    .line-item {
      .line-item-contents {
        grid-template-columns: calc((100% + 2rem) / 2) 1fr;
        gap: 2rem;
        padding: 0.75rem 1rem;

        .line-item-contents-text {
          line-height: 1.5rem;
        }
      }
    }
  }
}
