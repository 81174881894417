.login {
  padding: 1rem;

  .form-wrapper {
    background-color: white;
    border-radius: 1rem;
    max-width: 20rem;
    padding: 1.5rem;

    svg {
      width: 85%;
      margin: 0 auto 1rem;
    }

    .button-submit {
      margin-top: 0;
    }
  }
}

@media only screen and (min-width: 600px) {
  .login {
    padding: 3rem 0;

    .form-wrapper {
      padding: 2rem 3rem 3.5rem 3rem;
      max-width: 26rem;

      svg {
        width: auto;
        max-width: 18rem;
        margin-bottom: 2rem;
      }

      .button-submit {
        margin-top: 0.5rem;
      }
    }
  }
}
