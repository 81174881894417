$blue-dark: #203fa2;
$blue-medium: #354fa2;
$blue-light: #f2f5fc;

$green: #12996c;

$yellow: #f5d03b;

$pink-dark: #d62b62;
$pink-light: #f54981;

$grey-darkest: #222;
$grey-darker: #444;
$grey-dark: #666;
$grey-medium: #999;
$grey-light: #ccc;
$grey-lighter: #f0f0f0;
$grey-lightest: #f7f7f7;
