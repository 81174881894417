.form-error {
  .input-field {
    border: 1px solid $pink-light;
  }
}

.input-field {
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border: 1px solid $grey-lighter;
  padding: calculate-rem(11px) 1rem;
  border-radius: 0.25rem;
  font: 400 0.875rem/1.25rem $font;
}

textarea {
  resize: none;
}

@media only screen and (min-width: 700px) {
  .input-field {
    font: 400 1rem/1.625rem $font;
  }
}
